import {
  curryRightN,
  get,
  getOr,
  omit,
  pick,
  pipe,
  rest,
  values,
} from 'lodash/fp'
import queryString from 'query-string'
import { createSelector } from 'reselect'

import { everyFalse, everyTrue, multiplyArray } from '@masterplandev/utils'

import { env } from '@/env'

import {
  Parametric,
  ParametricResult,
  Singular,
  SingularResult,
} from './selectors.types'

export const routingSelector = get('router')
export const locationSelector = createSelector(routingSelector, get('location'))

export const searchSelector = createSelector(locationSelector, get('search'))
export const hashSelector = createSelector(locationSelector, get('hash'))

export const querySelector = createSelector(searchSelector, queryString.parse)
export const createQueryParamSelector = (key) =>
  createSelector(querySelector, get(key))

export const createEnvSelector = (variable) => () => get(variable, env)

export const debugSelector = createSelector(
  createQueryParamSelector('debug'),
  createEnvSelector('DEV'),
  rest(everyTrue),
)

export const multiplyArraySelector = (amount, key, selector) =>
  createSelector(selector, multiplyArray(amount, key))

export const selectorFactory = curryRightN<any, any, any>(2, createSelector)

export const metaSelectorFactory = selectorFactory(omit('data'))
export const fetchingSelectorFactory = selectorFactory(getOr(false, 'fetching'))
export const fetchedSelectorFactory = selectorFactory(getOr(false, 'fetched'))
export const failedSelectorFactory = selectorFactory(getOr(false, 'failed'))
export const errorSelectorFactory = selectorFactory(get('error'))
export const errorStatusSelectorFactory = selectorFactory(get('error_status'))
export const dataSelectorFactory = selectorFactory(get('data'))
export const requiresFetchSelectorFactory = selectorFactory(
  pipe([pick(['fetched', 'failed', 'fetching']), values, everyFalse]),
)
export const loadingSelectorFactory = selectorFactory(
  ({ fetched, failed, fetching }) =>
    (!fetched && !failed) || (!fetched && fetching),
)

export const generateBasicSelectors = <
  Data,
  Type extends Singular<Data> | Parametric<Data>,
>(
  rootSelector: Type,
) => {
  const root = rootSelector
  const data = dataSelectorFactory(rootSelector)
  const meta = metaSelectorFactory(rootSelector)
  const fetching = fetchingSelectorFactory(meta)
  const fetched = fetchedSelectorFactory(meta)
  const failed = failedSelectorFactory(meta)
  const error = errorSelectorFactory(meta)
  const errorStatus = errorStatusSelectorFactory(meta)
  const loading = loadingSelectorFactory(meta)
  const requiresFetch = requiresFetchSelectorFactory(meta)

  return {
    root,
    data,
    meta,
    fetching,
    fetched,
    failed,
    error,
    errorStatus,
    requiresFetch,
    loading,
  } as Type extends Singular<Data>
    ? SingularResult<Data>
    : ParametricResult<Data>
}

export const rootSelector = get('core')
export const errorSelector = createSelector(rootSelector, get('error'))
export const visibleTooltipsSelector = createSelector(
  rootSelector,
  getOr({}, 'visible_tooltips'),
)

export const nowSelector = () => new Date()
